import { createRouter, createWebHistory } from 'vue-router'
import rankingBoardView from '../views/rankingBoardView.vue'
import photoBoardView from '../views/photoBoardView.vue'
import dashBoardView from '../views/photoBoardView.vue'
import photoZoneView from '../views/photoZoneView.vue'
import photoBoothView from '../views/photoBoothView.vue'
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'rankingBoardView',
      component: () => import('../views/rankingBoardView.vue'),
      beforeEnter:function(to,form,next){
        window.router = 'rankingBoardView';
        next();
      }
    },
    {
      path: '/rankingMedia',
      name: 'rankingMediaView',
      component: () => import('../views/rankingMediaView.vue'),
      beforeEnter:function(to,form,next){
        window.router = 'rankingMediaView';
        next();
      }
    },
    {
      path: '/photo',
      name: 'photoBoardView',
      component: () => import('../views/photoBoardView.vue'),
      beforeEnter:function(to,form,next){
        window.router = 'photoBoardView';
        next();
      }
    },
    {
      path: '/board',
      name: 'dashBoardView',
      component: () => import('../views/dashBoardView.vue'),
      beforeEnter:function(to,form,next){
        window.router = 'dashBoardView';
        next();
      }
    },
    {
      path: '/photozone',
      name: 'photoZoneView',
      component: () => import('../views/photoZoneView.vue'),
      beforeEnter:function(to,form,next){
        next();
      }
    },
    {
      path: '/photobooth',
      name: 'photoBoothView',
      component: () => import('../views/photoBoothView.vue'),
      beforeEnter:function(to,form,next){
        window.router = 'photobooth';
        next();
      }
    },
    {
      path: '/final',
      name: 'fianl',
      component: () => import('../views/finalView.vue'),
      beforeEnter:function(to,form,next){
        window.router = 'final';
        next();
      }
    },
    { path: '/:catchAll(.*)', component: rankingBoardView },

  ]
})

/*router.beforeEach((to, from, next) => {
  document.querySelector('html').classList.remove(`${from.name}`)
  document.querySelector('html').classList.add(`${to.name}`)
  document.querySelector('body').classList.remove('onShow');
  setTimeout(()=>{
    window.scrollTo(0,0);
    setTimeout(()=>{
      document.querySelector('body').style.overflow='';
      document.querySelector('body').style.height='';
      document.querySelector('html').style.overflow='';
      document.querySelector('html').style.height='';
    },0);
  },0);
  next();
  window.dispatchEvent(new CustomEvent("routerpath", {
    detail: { name: to.path }
  }));
});*/


export default router