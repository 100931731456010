<script setup>
import {useMainStore} from "@/stores/mainStore";
const mainStore = useMainStore();
	mainStore.isInited = false;
	import { RouterLink, RouterView } from 'vue-router'
	let loadTK = () => {
		(function(d) {
			var config = {
					kitId: 'dkm2hid',
					scriptTimeout: 0,
					async: true
				},
				h=d.documentElement,
				t=setTimeout(function() {
					h.className = h.className.replace(/\bwf-loading\b/g,"") + " wf-inactive";
				}, config.scriptTimeout),
				tk=d.createElement("script"),
				f=false,
				s=d.getElementsByTagName("script")[0],
				a;
			h.className += " wf-loading";
			tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
			tk.async = true;
			tk.onload = tk.onreadystatechange = function() {
				a = this.readyState;
				if (f || a && a != "complete" && a != "loaded") return;
				f = true;
				clearTimeout(t);
				try {
					Typekit.load({
						active: onFontsLoaded,
						inactive: function() {
							console.error("Typekit fonts failed to load.");
						}
					});
				} catch (e) {
					console.error("An error occurred while loading Typekit fonts.", e);
				}
			};
			s.parentNode.insertBefore(tk, s);

			function onFontsLoaded() {
				console.log("--inited--");
				window.loaded = true;
				mainStore.isInited = true;
				// document.querySelector('body').classList.add('onShow');
			}
		})(document);
	}
	let init = () =>{
		loadTK()
	}
	window.addEventListener('load',init);
</script>

<script>
export default {
  name: "app",
  props: [],
  /*data(){
		return {
			message: 'Hello, Vue!'
		};
  },*/
  methods: {
  },
  mounted(){
  },
}
</script>
<template>
	<router-view />
</template>

<style lang="scss">
	@import 'assets/scss/page';
</style>